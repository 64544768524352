import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import TeamList from "./Teams/TeamList";
import Dashboard from "./Dashboard";
import MobileVersion from "./MobileVersion";
import PrivateRoute from "./PrivateRoute";
import DeleteInstaller from "./DeleteInstaller";
import Jobs from "./Jobs";
import ResetPswd from "./ResetPswd";
import HTMLPreview from "./HTMLPreview/HTMLPreview";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/resetpassword/:_id" element={<ResetPswd />} />
      <Route path="/mobile-version" element={<MobileVersion />} />
      <Route path="/delete-installer" element={<DeleteInstaller />} />
      <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
      <Route path="/traders" element={<PrivateRoute element={TeamList} />} />
      <Route path="/retailers" element={<PrivateRoute element={TeamList} />} />
      <Route path="/installers" element={<PrivateRoute element={TeamList} />} />
      <Route path="/jobs" element={<PrivateRoute element={Jobs}/>}/>
      <Route path="/priceUpdates" element ={<HTMLPreview/>}/>
    </Routes>
  );
}

export default App;
