import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const ProfileInstallationTeam = ({ onClose }) => {
  const token = localStorage.getItem("userToken");
  const id = localStorage.getItem("installerId");

  const [formdata, setFormdata] = useState({
    installer: {
      name: "",
      firstName: "",
      lastName: "",
      fullAddress: {
        addressType: "",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
      emailId: "",
      mobileNo: "",

      selectYourRole: [],

      permission: [],

      stateOfServices: [],
    },
    installerCECID: "",
    installerlicenceExpiry: "",
    installerCECLicensePhoto: "",
    electricianLicenseNo: "",
    electricianlicenceExpiry: "",
    electricianLicensePhoto: "",
    designerCECID: "",
    designerlicenceExpiry: "",
    designerCECLicensePhoto: "",
    gasfitterCECID: "",
    gasfitterLicensePhoto: "",
    gasfitterLicenseExpiry: "",
    plumberCECID: "",
    plumberCECLicensePhoto: "",
    plumberLicenseExpiry: "",
    profilePic: "",
  });

  //Get Team Data Starts

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/admin/installer/${id}`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data;
        const formatDateForInput = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        };
        if (data.installerlicenceExpiry) {
          data.installerlicenceExpiry = formatDateForInput(
            data.installerlicenceExpiry
          );
        }
        if (data.designerlicenceExpiry) {
          data.designerlicenceExpiry = formatDateForInput(
            data.designerlicenceExpiry
          );
        }
        if (data.electricianlicenceExpiry) {
          data.electricianlicenceExpiry = formatDateForInput(
            data.electricianlicenceExpiry
          );
        }
        if (data.plumberLicenseExpiry) {
          data.plumberLicenseExpiry = formatDateForInput(
            data.plumberLicenseExpiry
          );
        }
        if (data.gasfitterLicenseExpiry) {
          data.gasfitterLicenseExpiry = formatDateForInput(
            data.gasfitterLicenseExpiry
          );
        }
        setFormdata(data);
      } catch (err) {
        console.log("Error:", err);
      }
    };
    getData();
  }, []);

  //Get Team Data Ends

  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const showProfileModal = () => {
    setIsProfileModalVisible(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalVisible(false);
  };

  const [isInstallerModalVisible, setIsInstallerModalVisible] = useState(false);

  const showInstallerModal = () => {
    setIsInstallerModalVisible(true);
  };

  const closeInstallerModal = () => {
    setIsInstallerModalVisible(false);
    document.body.classList.remove("page-modal-open");
  };

  const [isElectricianModalVisible, setIsElectricianModalVisible] =
    useState(false);

  const showElectricianModal = () => {
    setIsElectricianModalVisible(true);
  };

  const closeElectricianModal = () => {
    setIsElectricianModalVisible(false);
  };

  const [isDesignerModalVisible, setIsDesignerModalVisible] = useState(false);

  const showDesignerModal = () => {
    setIsDesignerModalVisible(true);
  };

  const closeDesignerModal = () => {
    setIsDesignerModalVisible(false);
  };

  const [isPlumberModalVisible, setIsPlumberModalVisible] = useState(false);

  const showPlumberModal = () => {
    setIsPlumberModalVisible(true);
  };

  const closePlumberModal = () => {
    setIsPlumberModalVisible(false);
  };

  const [isGasfitterModalVisible, setIsGasfitterModalVisible] = useState(false);

  const showGasfitterModal = () => {
    setIsGasfitterModalVisible(true);
  };

  const closeGasfitterModal = () => {
    setIsGasfitterModalVisible(false);
  };

  return (
    <>
      {isProfileModalVisible && (
        <div className="modal-backdrop" onClick={closeProfileModal}>
          <div className="modal-centered" onClick={(e) => e.stopPropagation()}>
            <div style={{ display: "flex", gap: "295px" }}>
              <h3>Profile Picture</h3>
              <button
                className="add-model-close-btn"
                onClick={closeProfileModal}
              >
                ✕
              </button>
            </div>
            <div className="licenceImage">
              <img src={formdata.profilePic} alt="" />
            </div>
          </div>
        </div>
      )}
      {isInstallerModalVisible && (
        <div className="modal-backdrop" onClick={closeInstallerModal}>
          <div className="modal-centered" onClick={(e) => e.stopPropagation()}>
            <div style={{ display: "flex", gap: "213px" }}>
              <h3>Installer Licence Image</h3>
              <button
                className="add-model-close-btn"
                onClick={closeInstallerModal}
              >
                ✕
              </button>
            </div>
            <div className="licenceImage">
              <img src={formdata.installerCECLicensePhoto} alt="" />
            </div>
          </div>
        </div>
      )}
      {isElectricianModalVisible && (
        <div className="modal-backdrop" onClick={closeElectricianModal}>
          <div className="modal-centered" onClick={(e) => e.stopPropagation()}>
            <div style={{ display: "flex", gap: "188px" }}>
              <h3>Electrician Licence Image</h3>
              <button
                className="add-model-close-btn"
                onClick={closeElectricianModal}
              >
                ✕
              </button>
            </div>
            <div className="licenceImage">
              <img src={formdata.electricianLicensePhoto} alt="" />
            </div>
          </div>
        </div>
      )}
      {isDesignerModalVisible && (
        <div className="modal-backdrop" onClick={closeDesignerModal}>
          <div className="modal-centered" onClick={(e) => e.stopPropagation()}>
            <div style={{ display: "flex", gap: "205px" }}>
              <h3>Designer Licence Image</h3>
              <button
                className="add-model-close-btn"
                onClick={closeDesignerModal}
              >
                ✕
              </button>
            </div>
            <div className="licenceImage">
              <img src={formdata.designerCECLicensePhoto} alt="" />
            </div>
          </div>
        </div>
      )}
      {isPlumberModalVisible && (
        <div className="modal-backdrop" onClick={closePlumberModal}>
          <div className="modal-centered" onClick={(e) => e.stopPropagation()}>
            <div style={{ display: "flex", gap: "205px" }}>
              <h3>Plumber Licence Image</h3>
              <button
                className="add-model-close-btn"
                onClick={closePlumberModal}
              >
                ✕
              </button>
            </div>
            <div className="licenceImage">
              <img src={formdata.plumberCECLicensePhoto} alt="" />
            </div>
          </div>
        </div>
      )}
      {isGasfitterModalVisible && (
        <div className="modal-backdrop" onClick={closeGasfitterModal}>
          <div className="modal-centered" onClick={(e) => e.stopPropagation()}>
            <div style={{ display: "flex", gap: "205px" }}>
              <h3>Gasfitter Licence Image</h3>
              <button
                className="add-model-close-btn"
                onClick={closeGasfitterModal}
              >
                ✕
              </button>
            </div>
            <div className="licenceImage">
              <img src={formdata.gasfitterLicensePhoto} alt="" />
            </div>
          </div>
        </div>
      )}
      <div className="profileview-model-backdrop">
        <div className="profileview-model-content" style={{ height: "685px" }}>
          <div className="profile-model-header">
            <h3 style={{ display: "flex", gap: "10px" }}>
              {formdata.installer.name}{" "}
              <span>
                {" "}
                {formdata.installer.selectYourRole.map((role, index) => (
                  <span key={index}>
                    {role}
                    {index < formdata.installer.selectYourRole.length - 1 &&
                    formdata.installer.selectYourRole[index + 1]
                      ? ", "
                      : " "}
                  </span>
                ))}{" "}
              </span>
            </h3>
            <button
              className="invite-model-close-btn"
              onClick={() => {
                localStorage.removeItem("installerId");
                onClose();
              }}
            >
              ✕
            </button>
          </div>
          <div className="row">
            <span className="viewbottom-border"></span>
          </div>
          <div className="row">
            <div style={{ display: "flex" }}>
              <div className="left-profile-photo">
                <img
                  style={{
                    width: "112px",
                    height: "112px",
                    borderRadius: "15px",
                  }}
                  src={formdata.profilePic}
                  alt=""
                  onClick={showProfileModal}
                />
              </div>
              <div style={{ flexDirection: "column" }}>
                <div className="right-profile-details">
                  <img
                    src={`${image}/Assets/Teamdashboard/Location.svg`}
                    alt=""
                  />
                  <span className="profile-addresss">
                    {formdata?.installer?.fullAddress?.unitType &&
                      `${formdata?.installer?.fullAddress?.unitType} `}
                    {formdata?.installer?.fullAddress?.unitNumber &&
                      `${formdata?.installer?.fullAddress?.unitNumber}, `}
                    {formdata?.installer?.fullAddress?.streetNumber &&
                      `${formdata?.installer?.fullAddress?.streetNumber} `}
                    {formdata?.installer?.fullAddress?.streetName &&
                      `${formdata?.installer?.fullAddress?.streetName} `}
                    {formdata?.installer?.fullAddress?.streetType &&
                      `${formdata?.installer?.fullAddress?.streetType}, `}
                    {formdata?.installer?.fullAddress?.suburb &&
                      `${formdata?.installer?.fullAddress?.suburb}, `}
                    {formdata?.installer?.fullAddress?.state &&
                      `${formdata?.installer?.fullAddress?.state}, `}
                    {formdata?.installer?.fullAddress?.postCode &&
                      `${formdata?.installer?.fullAddress?.postCode}`}
                  </span>
                </div>
                <div className="right-profile-details">
                  <img
                    src={`${image}/Assets/Teamdashboard/Phone-receiver.svg`}
                    alt=""
                  />
                  <span className="phone-receiver">
                    {formdata.installer.mobileNo}
                  </span>
                </div>
                <div className="right-profile-details">
                  <img src={`${image}/Assets/Teamdashboard/Email.svg`} alt="" />
                  <span className="email-detail">
                    {formdata.installer.emailId}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "flex-start",
              }}
            >
              {formdata.installer.selectYourRole.includes("Installer") ? (
                <div className="addmain-details">
                  <b>Installer</b>
                  <p className="view-main-details">
                    <b>CEC ID: </b> {formdata.installerCECID}
                  </p>
                  <span className="view-main-details">
                    <b>Licence Expiry: </b> {formdata.installerlicenceExpiry}
                  </span>
                  <span className="view-main-details">
                    <b>CEC License ID Photo: </b>
                    <img
                      src={`${image}/Assets/TeamProfile/cce-photo.svg`}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const licensePhoto = formdata?.installerCECLicensePhoto;
                        if (licensePhoto && licensePhoto.endsWith(".pdf")) {
                          window.open(licensePhoto, "_blank");
                        } else {
                          showInstallerModal();
                        }
                      }}
                    />
                  </span>
                </div>
              ) : null}
              {formdata.installer.selectYourRole.includes("Electrician") ? (
                <div className="addmain-details">
                  <b>Electrician</b>
                  <p className="view-main-details">
                    <b>CEC ID: </b> {formdata.electricianLicenseNo}
                  </p>
                  <span className="view-main-details">
                    <b>Licence Expiry: </b> {formdata.electricianlicenceExpiry}
                  </span>
                  <span className="view-main-details">
                    <b>Licence Photo: </b>
                    <img
                      src={`${image}/Assets/TeamProfile/cce-photo.svg`}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const licensePhoto = formdata?.electricianLicensePhoto;
                        if (licensePhoto && licensePhoto.endsWith(".pdf")) {
                          window.open(licensePhoto, "_blank");
                        } else {
                          showElectricianModal();
                        }
                      }}
                    />
                  </span>
                </div>
              ) : null}
              {formdata.installer.selectYourRole.includes("Designer") ? (
                <div className="addmain-details">
                  <b>Designer</b>
                  <p className="view-main-details">
                    <b>CEC ID: </b> {formdata.designerCECID}
                  </p>
                  <span className="view-main-details">
                    <b>Licence Expiry: </b> {formdata.designerlicenceExpiry}
                  </span>
                  <span className="view-main-details">
                    <b>CEC License ID Photo: </b>
                    <img
                      src={`${image}/Assets/TeamProfile/cce-photo.svg`}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const licensePhoto = formdata?.designerCECLicensePhoto;
                        if (licensePhoto && licensePhoto.endsWith(".pdf")) {
                          window.open(licensePhoto, "_blank");
                        } else {
                          showDesignerModal();
                        }
                      }}
                    />
                  </span>
                </div>
              ) : null}
              {formdata.installer.selectYourRole.includes("Plumber") ? (
                <div className="addmain-details">
                  <b>Plumber</b>
                  <p className="view-main-details">
                    <b>CEC ID: </b> {formdata.plumberCECID}
                  </p>
                  <span className="view-main-details">
                    <b>Licence Expiry: </b> {formdata.plumberLicenseExpiry}
                  </span>
                  <span className="view-main-details">
                    <b>Accreditation Photo: </b>
                    <img
                      src={`${image}/Assets/TeamProfile/cce-photo.svg`}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const licensePhoto = formdata?.plumberCECLicensePhoto;
                        if (licensePhoto && licensePhoto.endsWith(".pdf")) {
                          window.open(licensePhoto, "_blank");
                        } else {
                          showPlumberModal();
                        }
                      }}
                    />
                  </span>
                </div>
              ) : null}
              {formdata.installer.selectYourRole.includes("Gasfitter") ? (
                <div className="addmain-details">
                  <b>Gasfitter</b>
                  <p className="view-main-details">
                    <b>CEC ID: </b> {formdata.gasfitterCECID}
                  </p>
                  <span className="view-main-details">
                    <b>Licence Expiry: </b> {formdata.gasfitterLicenseExpiry}
                  </span>
                  <span className="view-main-details">
                    <b>Accreditation Photo: </b>
                    <img
                      src={`${image}/Assets/TeamProfile/cce-photo.svg`}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const licensePhoto = formdata?.gasfitterLicensePhoto;
                        if (licensePhoto && licensePhoto.endsWith(".pdf")) {
                          window.open(licensePhoto, "_blank");
                        } else {
                          showGasfitterModal();
                        }
                      }}
                    />
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="want-serve">
              <b>Want to Serve in</b>
              {formdata.installer.stateOfServices &&
              formdata.installer.stateOfServices.length > 0 ? (
                <span
                  className="serve-detail"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "3px",
                  }}
                >
                  {formdata.installer.stateOfServices.map((role, index) => (
                    <span key={index}>
                      {role}
                      {index < formdata.installer.stateOfServices.length - 1 &&
                      formdata.installer.stateOfServices[index + 1]
                        ? ", "
                        : " "}
                    </span>
                  ))}
                </span>
              ) : (
                <div>
                  <p>No State of Service is Selected</p>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="want-serve">
            <b>Permissions</b>
            {formdata.installer.permission &&
            formdata.installer.permission.length > 0 ? (
              <div
                className="popup-checkbox"
                style={{ display: "flex", gap: "3px", flexWrap: "wrap" }}
              >
                {formdata.installer.permission.map((role, index) => (
                  <span key={index}>
                    {role}
                    {index < formdata.installer.permission.length - 1 &&
                    formdata.installer.permission[index + 1]
                      ? ", "
                      : " "}
                  </span>
                ))}
              </div>
            ) : (
              <div>
                <p>No Permission Allotted</p>
              </div>
            )}
          </div>
          <br />
          <div className="row">
            <span className="viewbottom-border"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileInstallationTeam;
