import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

const AddTraderAndRetailer = ({ onClose }) => {
  const token = localStorage.getItem("userToken");

  //DropDowns Starts
  const [addressTypeOptions, setAddressTypeOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/get/masterData?type=addressType,roles,unitType,streetType,state`
      )
      .then((response) => {
        const master = response.data;
        setAddressTypeOptions(master.data[0].values);
        setRoleOptions(master.data[1].values);
        setStateOptions(master.data[2].values);
        setStreetOptions(master.data[3].values);
        setUnitOptions(master.data[4].values);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //DropDowns Ends

  const [newUser, setNewUser] = useState({
    basicInformation: {
      firstName: "",
      lastName: "",
      emailId: "",
      role: "",
    },
    companyInformation: {
      ABN: null,
      tradingName: "",
      legalEntityName: "",
      mobile: "",
      address: {
        addressType: "Physical",
        unitType: "",
        unitNo: null,
        streetNo: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postcode: null,
      },
    },
    bankInformation: {
      bankName: "",
      accName: "",
      bsbNo: null,
      accNo: null,
    },
    userName: "",
    password: "Trader@123",
    userType: "Trader",
  });

  //manualButton
  const [manualButton, setMenualButton] = useState(false);
  const swithingAddress = () => {
    setMenualButton(!manualButton);
  };
  //manualButton

  // google auto address
  const [address, SetAddress] = useState("");
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const lat = result[0].geometry.location.lat();
    const lng = result[0].geometry.location.lng();
    setSelectedLocation({
      lat: lat,
      lng: lng,
    });
    SetAddress(result[0].formatted_address);
    const addressComponents = result[0].address_components;
    const getComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );

      if (component) {
        return type === "administrative_area_level_1"
          ? component.short_name || ""
          : component.long_name || "";
      } else {
        return "";
      }
    };
    const fullStreetName = getComponent("route");
    let streetName, streetType;
    if (fullStreetName.split(" ").length > 2) {
      const words = fullStreetName.split(" ");
      streetType = words.pop();
      streetName = words.join(" ");
    } else {
      [streetName, streetType] = fullStreetName.split(" ", 2);
    }
    const streetNo = getComponent("street_number");
    const suburb =
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("political");
    const state = getComponent("administrative_area_level_1");
    const postcode = getComponent("postal_code");
    setNewUser((prevState) => ({
      ...prevState,
      companyInformation: {
        ...prevState.companyInformation,
        address: {
          ...prevState.companyInformation.address,
          streetNo: streetNo,
          streetName: streetName,
          streetType: streetType,
          suburb: suburb,
          state: state,
          postcode: postcode,
        },
      },
    }));

    setMenualButton(!manualButton);
  };
  // google auto address

  //map

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  //map

  //Functions Starts
  const handleInputForBasic = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      basicInformation: {
        ...prev.basicInformation,
        [name]: value,
      },
    }));
  };

  const handleInputCompanyDetail = (e) => {
    const { name, value, type, checked } = e.target;
    setNewUser((p) => ({
      ...p,
      companyInformation: {
        ...p.companyInformation,
        [name]: value,
      },
    }));
  };

  const handleInputAddress = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      companyInformation: {
        ...prev.companyInformation,
        address: {
          ...prev.companyInformation.address,
          [name]: value,
        },
      },
    }));
  };

  const handleInputBankDetails = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      bankInformation: {
        ...prev.bankInformation,
        [name]: value,
      },
    }));
  };

  const handleInputForCreds = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  //Functions Ends

  //API Call Starts
  const SubmitFormSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/traders/signUp`, newUser, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        alert("Trader Added Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
      }
    } catch (error) {
      if (error.response?.status === 400) {
        alert(error.response?.data?.message);
      } else {
        alert(error.response?.data?.message);
      }
    }
  };
  //API Call Ends
  console.log(newUser);
  return (
    <div className="add-model-backdrop">
      <div className="add-model-content">
        <div className="add-model-header">
          <h3>Add New Trader</h3>
          <button className="add-model-close-btn" onClick={onClose}>
            ✕
          </button>
        </div>
        <div className="row">
          <span className="popupbottomadd-border"></span>
        </div>

        <form className="add-model-form" onSubmit={SubmitFormSignup}>
          <label>Contact Details</label>
          <div className="row">
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                className="add-name"
                type="text"
                name="firstName"
                maxLength={30}
                placeholder="First Name*"
                value={newUser?.basicInformation?.firstName || ""}
                onChange={handleInputForBasic}
                required
              />
              <input
                className="add-name"
                type="text"
                name="lastName"
                maxLength={30}
                placeholder="Last Name*"
                value={newUser?.basicInformation?.lastName || ""}
                onChange={handleInputForBasic}
                required
              />
              <input
                className="add-email"
                type="email"
                name="emailId"
                // maxLength={30}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                placeholder="Email Id*"
                value={newUser?.basicInformation?.emailId || ""}
                onChange={handleInputForBasic}
                required
              />
              <select
                className="add-phone"
                value={newUser.basicInformation.role || ""}
                name="role"
                onChange={handleInputForBasic}
                required
              >
                <option value="" defaultValue>
                  Role<span>*</span>
                </option>
                {roleOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <label>Registration Details</label>
          <div className="row">
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                className="add-name"
                type="text"
                placeholder="Username*"
                maxLength={50}
                value={newUser.userName || ""}
                name="userName"
                onChange={handleInputForCreds}
                onKeyDown={(e) => {
                  if (e.target.value.length > 50) {
                    window.alert(
                      "User Name must be of less than 50 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                required
                autoComplete="off"
              />
            </div>
          </div>
          <label>Company Details</label>
          <div className="row">
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                className="add-name"
                type="number"
                placeholder="Australian Business Number*"
                maxLength={11}
                value={newUser.companyInformation.ABN || ""}
                name="ABN"
                onChange={handleInputCompanyDetail}
                onKeyDown={(e) => {
                  if (e.target.value.length > 11) {
                    window.alert("ABN number must be of 11 digits.");
                    e.target.value = " ";
                  }
                }}
                pattern="[0-9]*"
                inputmode="numeric"
                required
                autoComplete="off"
              />
              <input
                className="add-name"
                type="text"
                placeholder="Company Name*"
                maxLength={50}
                value={newUser.companyInformation.tradingName || ""}
                name="tradingName"
                onChange={handleInputCompanyDetail}
                onKeyDown={(e) => {
                  if (e.target.value.length > 50) {
                    window.alert(
                      "Company Name must be of less than 50 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                required
                autoComplete="off"
              />
              {console.log(newUser)}
              <input
                className="add-name"
                type="text"
                placeholder="Legal Entity Name"
                maxLength={50}
                value={newUser.companyInformation.legalEntityName || ""}
                name="legalEntityName"
                onChange={handleInputCompanyDetail}
                onKeyDown={(e) => {
                  if (e.target.value.length > 50) {
                    window.alert(
                      "Legal Entity Name must be of less than 50 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                autoComplete="off"
              />
              <input
                className="add-name"
                type="text"
                placeholder="Phone No*"
                maxLength={60}
                value={newUser.companyInformation.mobile || ""}
                name="mobile"
                onChange={handleInputCompanyDetail}
                onInput={(e) => {
                  if (e.target.value.length > 14) {
                    e.target.value = e.target.value.slice(0, 14);
                    window.alert(
                      "Phone Number must be of less than 14 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                required
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row">
            <div style={{ display: "flex" }}>
              <div className="Signup-Adress-container">
                {manualButton ? (
                  <div>
                    <div className="main-address-block">
                      <div className="row">
                        <div className="address-main-boxes address-box">
                          <select
                            className="address-type"
                            name="addressType"
                            value={
                              newUser.companyInformation.address.addressType ||
                              ""
                            }
                            onChange={handleInputAddress}
                          >
                            <option value="">Address Type</option>
                            {addressTypeOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="address-main-boxes">
                          <select
                            className="address-type"
                            name="unitType"
                            value={
                              newUser.companyInformation.address.unitType || ""
                            }
                            onChange={handleInputAddress}
                          >
                            <option value="">Unit Type</option>
                            {unitOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="unit-main-boxes">
                          <input
                            className="unit-no"
                            placeholder="Unit Number"
                            name="unitNo"
                            value={
                              newUser.companyInformation.address.unitNo || ""
                            }
                            onChange={handleInputAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 8) {
                                window.alert(
                                  "Unit number must be less than or equal to 8 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="address-main-boxes address-box">
                          <input
                            className="address-type"
                            placeholder="Street Number*"
                            maxlength="6"
                            name="streetNo"
                            value={
                              newUser.companyInformation.address.streetNo || ""
                            }
                            onChange={handleInputAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 6) {
                                window.alert(
                                  "Street number must be less than or equal to 6 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                            required
                          />
                          <span className=""></span>
                        </div>
                        <div className="address-main-boxes">
                          <input
                            className="address-type"
                            type="text"
                            maxLength={50}
                            name="streetName"
                            placeholder="Street Name*"
                            value={
                              newUser.companyInformation.address.streetName ||
                              ""
                            }
                            onChange={handleInputAddress}
                            required
                          />
                        </div>
                        <div className="unit-main-boxes">
                          <select
                            className="street-type"
                            name="streetType"
                            value={
                              newUser.companyInformation.address.streetType ||
                              ""
                            }
                            onChange={handleInputAddress}
                            required
                          >
                            <option value="">
                              Street Type<span className="required">*</span>
                            </option>
                            {streetOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="address-main-boxes address-box">
                          <input
                            className="address-type"
                            type="text"
                            maxLength={10}
                            name="suburb"
                            placeholder="Suburb*"
                            value={
                              newUser.companyInformation.address.suburb || ""
                            }
                            onChange={handleInputAddress}
                            required
                          />
                        </div>
                        <div className="state-main-boxes">
                          <select
                            className="state-code"
                            value={
                              newUser.companyInformation.address.state || ""
                            }
                            onChange={handleInputAddress}
                            name="state"
                            required
                          >
                            <option value="">
                              State<span className="required">*</span>
                            </option>
                            {stateOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div
                          className="address-main-boxes"
                          style={{ marginLeft: "10px" }}
                        >
                          <input
                            className="address-type"
                            type="number"
                            placeholder="Post Code*"
                            name="postcode"
                            value={
                              newUser.companyInformation.address.postcode || ""
                            }
                            onChange={handleInputAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 4) {
                                window.alert(
                                  "Post Code must be equal to 4 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addmap-section">
                      {address ? (
                        <div style={{ position: "relative", right: "0" }}>
                          <GoogleMap
                            mapContainerStyle={{
                              width: "421px",
                              height: "196px",
                            }}
                            center={selectedLocation}
                            zoom={15}
                            onLoad={onMapLoad}
                            options={{
                              mapTypeControl: true,
                            }}
                          >
                            {selectedLocation && (
                              <MarkerF
                                position={{
                                  lat: selectedLocation.lat,
                                  lng: selectedLocation.lng,
                                }}
                              />
                            )}
                          </GoogleMap>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="Signup-autoAdress-container">
                    <div className="Signup-autoAdress-border">
                      <PlacesAutocomplete
                        value={address}
                        onChange={SetAddress}
                        onSelect={handleSelect}
                        searchOptions={{
                          componentRestrictions: { country: "au" },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="address-input">
                            <input
                              className="Signup-autoAdress-input"
                              id="address"
                              {...getInputProps({
                                type: "text",
                                placeholder: "Address*",
                              })}
                              required
                            />
                            <div className="address-auto-complete">
                              {loading ? <div>Loading</div> : null}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                  color: suggestion.active ? "#fff" : "#000",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      <div className="address-auto-complete"></div>
                    </div>
                    <div className="Signup-menualAdress-container">
                      <button
                        className="Signup-switchingAdress-btn"
                        onClick={swithingAddress}
                      >
                        Enter Manually
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <label>Bank Details</label>
          <div className="row">
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                className="add-name"
                type="text"
                placeholder="Bank Name*"
                maxLength={50}
                value={newUser.bankInformation.bankName || ""}
                name="bankName"
                onChange={handleInputBankDetails}
                onKeyDown={(e) => {
                  if (e.target.value.length > 50) {
                    window.alert(
                      "Bank Name must be of less than 50 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                required
                autoComplete="off"
              />
              <input
                className="add-name"
                type="text"
                placeholder="Account Name*"
                maxLength={50}
                value={newUser.bankInformation.accName || ""}
                name="accName"
                onChange={handleInputBankDetails}
                onKeyDown={(e) => {
                  if (e.target.value.length > 50) {
                    window.alert(
                      "Account Name must be of less than 50 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                required
                autoComplete="off"
              />
              <input
                className="add-name"
                type="number"
                placeholder="BSB No*"
                maxLength={6}
                value={newUser.bankInformation.bsbNo || ""}
                name="bsbNo"
                onChange={handleInputBankDetails}
                onInput={(e) => {
                  if (e.target.value.length > 6) {
                    e.target.value = e.target.value.slice(0, 6);
                    window.alert("BSB Number must be less than 6 characters.");
                  }
                }}
                required
                autoComplete="off"
              />
              <input
                className="add-name"
                type="number"
                placeholder="Account No.*"
                maxLength={10}
                value={newUser.bankInformation.accNo || ""}
                name="accNo"
                onChange={handleInputBankDetails}
                onKeyDown={(e) => {
                  if (e.target.value.length > 10) {
                    window.alert(
                      "Account Number must be of less than 10 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                onInput={(e) => {
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                    window.alert(
                      "Account Number must be of less than 10 characters."
                    );
                    e.target.value = " ";
                  }
                }}
                required
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row">
            <span className="popupbottomadd-border"></span>
          </div>
          <div style={{ display: "flex" }}>
            <div className="add-model-actions">
              <button type="button" onClick={onClose} className="cancel-hover">
                Cancel
              </button>
              <button type="submit" className="submit-hover">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTraderAndRetailer;
