import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarGraph = ({ data }) => {
  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <p
            className="label"
            style={{
              marginBottom: "4px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >{`Year: ${label}`}</p>
          <p
            className="value"
            style={{ fontSize: "16px" }}
          >{` ${payload[0]?.name} ${payload[0].value}`}</p>
          <p className="value" style={{ fontSize: "16px" }}>{` ${
            payload[1]?.dataKey
          } ${payload[1]?.value || "0"}`}</p>
          <p className="value" style={{ fontSize: "16px" }}>{` ${
            payload[2]?.dataKey
          } ${payload[2]?.value || "0"}`}</p>
        </div>
      );
    }

    return null;
  };
  const maxValue = Math.max(...data.map((item) => item.Retailers));
  return (
    <ResponsiveContainer width="55%" height={500}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 20,
          bottom: 5,
        }}
        padding={{
          top: 10,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="year" />
        <YAxis type="number" domain={[0, maxValue + 10]} />
        <Tooltip content={customTooltip} />
        <Legend />
        <Bar dataKey="Traders" fill="#82ca9d" />
        <Bar dataKey="Retailers" fill="#8884d8" />
        <Bar dataKey="Installers" fill="#ffc658" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
