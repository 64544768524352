import React from 'react';

const HTMLPreview = () => {
  const htmlContent = `
  <!DOCTYPE html>
  <html>
    <head>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Price Update</title>
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="stylesheet" href="" />
      <style>
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 0;
        padding: 0;
        background-color: #f0f0f0;
        
      }
      .container{
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        background-color: #ffffffe6;
        border-radius: 8px;
        border:4px solid #2242aa; 
        box-shadow: 0 0 10px #2242aa;
      }
      .buttonContainer{
        width: 280px;
        padding:6px;
        background-color: #2242aa;
        color: #f0f0f0;
        border:none;
        border-radius: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 2px;

      }
      .FirstInnerContainer{
        background-color: #e6eefc;
        padding:20px 5px
      }
      .SecondInnerContainer{
        background-color: #fff;
        padding:20px 5px
      }
      .paraText{
        width: 50%;
        display: inline-block;
        color:rgb(36, 64, 172);
        margin-bottom:15px;
      }
      
      .LoginButtonContainer{
        width: 22%;
        padding:8px;
        background-color: #2242aa;
        color: #f0f0f0;
        border:none;
        border-radius: 50px;
        font-size: 16px;
        font-weight: bold;
        cursor:pointer;
      }  
      </style>
    </head>
    <body >
      <div>
        <table
          style="
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
          "
        >
        <tr >
          <td style="margin-top:0 ; color: #696969;width: 50%;">Price Update - 10/07/24 </td>
          <td style="text-align:right">

          <a style="text-decoration: none; color:#696969;width:50%;" href="https://admin.uveaa.com/priceUpdates" target="_blank"
          >View this email in your browser</a
        > 
        </td>
      </tr>
      </table>
       <div class="container">
        <div class="FirstInnerContainer">
          <div style="text-align: center;">
            <img src="https://installer-profile-photos.s3.ap-southeast-2.amazonaws.com/uploads/da45b02e-a196-44d0-b9e0-138fd12c4478-uveaa-logo.png"/>
            <p style="font-size: 16px;margin-top: 0;color:#666666;font-weight:600">SIMPLIFYING COMPLIANCE</p>
          </div>
          <div style="text-align: center;">
            <p style="
            padding-top:10px;
            color: #2242aa;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 2px;">
              PRICING SOLUTIONS
            </p>
          </div>
          <div style="text-align: center;margin-top:13px">
          <p class="paraText">
            We promise you'll get the best results with our custom solutions and an efficient workflow.
          </p>
          </div>
          <div style="text-align: center;">
          <a href="https://retailer.uveaa.com/" target="_blank" style="cursor:pointer">
            <button style="width: 22%;
            padding:8px;
            background-color: #2242aa;
            color: #f0f0f0;
            border:none;
            border-radius: 50px;
            font-size: 16px;
            font-weight: bold;
            cursor:pointer;
           margin:20px 0;">
              LOGIN NOW
            </button>
            </a>
          </div>
        </div>
        <div class="SecondInnerContainer">
            <div style="text-align: center;">
              <h2 class="paraText" style="width: 100%;margin-bottom: 0;">
                WATER HEATING
              </h2>
              <p class="paraText"style="width: 69%;margin-top:18px;margin-bottom:5px ">
                We specialize in installation services for both residential and small businesses, ensuring quality and efficiency every step of the way.
              </p>
            </div>
            <div style="width: 100%;text-align: center;">
             
            <table style="width: 100%;">
                <tr>
                    <td style="width:50%;padding:24px" align="center" >
                        <h3 style="font-family: Arial, sans-serif; font-size: 18px; color: #2242aa; margin-top:15px">BEST PRICE</h3>
                        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #666666; margin:  0;">(Paid in 20 working days)</p>
                        <p style="font-family: Arial, sans-serif; font-size: 18px; color: #2242aa; margin: 0;font-weight: 600;">$38.30 <span style="font-size: 11px;display:inline;color: #666666">(Per STC)</span></p>
                    </td>
                    <td style="width: 50%;padding: 24px;" align="center" >
                      <h3 style="font-family: Arial, sans-serif; font-size: 18px; color: #2242aa; margin-top:15px"">FAST TRACK PRICE</h3>
                        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #666666; margin: 0;">(Paid in 1 working day)</p>
                        <p style="font-family: Arial, sans-serif; font-size: 18px; color: #2242aa; margin: 0;font-weight: 600;">$37.75 <span style="font-size: 11px;display:inline;color: #666666">(Per STC)</span></p>
                    </td>
                </tr>
            </table>
             
            <table style="width: 100%;">
            <tr>
                <td align="center" style="padding: 20px; font-family: Arial, sans-serif; font-size: 12px; line-height: 18px; color: #2242aa;">
                    <p style="margin: 0;">Copyright@2024 UVEAA AUSTRALIA PTY LTD, All Rights Reserved</p>
                    <p style="margin: 5px 0;">9/204 Alice St Brisbane QLD 4000</p>
                    <p style="margin: 0;">07 3041 1155</p>
                </td>
            </tr>
        </table>
            </div>
        </div>
       </div>
      </div>
      <script src="" async defer></script>
    </body>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default HTMLPreview;