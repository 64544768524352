import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const image = process.env.PUBLIC_URL;

const Aside = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomeActive = location.pathname === "/dashboard";
  const isTradersActive = location.pathname === "/traders";
  const isRetailersActive = location.pathname === "/retailers";
  const isInstallersActive = location.pathname === "/installers";
  const isConfigurationActive = location.pathname === "/config";
  const isJobsActive = location.pathname==="/jobs"
  return (
    <div className="left-panel">
      <div className="dashboard-logo">
        <img
          src={`${image}/Assets/LeftPanel/dashboard-uveaa-logo.svg`}
          alt=""
          className="Logo-image-sideMenu"
        />
      </div>
      <div className="sideBarMenu">
        <button
          className={`left-part ${isHomeActive ? "active" : ""}`}
          style={{ border: "none" }}
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <div className="left-img">
            <img
              src={`${image}/Assets/LeftPanel/teams.svg`}
              alt=""
              className={`default-image ${isHomeActive ? "hide" : ""}`}
            />
            <img
              src={`${image}/Assets/LeftPanel/teams-white.svg`}
              alt=""
              className={`selected-image ${isHomeActive ? "" : "hide"}`}
            />
          </div>
          <div className="left-text">Dashboard</div>
        </button>
          <button
            className={`left-part ${isJobsActive ? "active" : ""}`}
            style={{ border: "none" }}
            onClick={() => { navigate("/jobs");
             }}
              >
                <div className="left-img">
                  <img
                    src={`${image}/Assets/LeftPanel/jobs.svg`}
                    alt=""
                    className={`default-image ${isJobsActive ? "hide" : ""}`}
                  />
                  <img
                    src={`${image}/Assets/LeftPanel/jobs-white.svg`}
                    alt=""
                    className={`selected-image ${!isJobsActive ? "hide" : ""}`}
                  />
                </div>
                <div className="left-text">Jobs</div>
              </button>
        <button
          className={`left-part ${isTradersActive ? "active" : ""}`}
          style={{ border: "none" }}
          onClick={() => {
            navigate("/traders");
          }}
        >
          <div className="left-img">
            <img
              src={`${image}/Assets/LeftPanel/teams.svg`}
              alt=""
              className={`default-image ${isTradersActive ? "hide" : ""}`}
            />
            <img
              src={`${image}/Assets/LeftPanel/teams-white.svg`}
              alt=""
              className={`selected-image ${isTradersActive ? "" : "hide"}`}
            />
          </div>
          <div className="left-text">Traders</div>
        </button>
        <button
          className={`left-part ${isRetailersActive ? "active" : ""}`}
          style={{ border: "none" }}
          onClick={() => {
            navigate("/retailers");
          }}
        >
          <div className="left-img">
            <img
              src={`${image}/Assets/LeftPanel/teams.svg`}
              alt=""
              className={`default-image ${isRetailersActive ? "hide" : ""}`}
            />
            <img
              src={`${image}/Assets/LeftPanel/teams-white.svg`}
              alt=""
              className={`selected-image ${isRetailersActive ? "" : "hide"}`}
            />
          </div>
          <div className="left-text">Retailers</div>
        </button>
        <button
          className={`left-part ${isInstallersActive ? "active" : ""}`}
          style={{ border: "none" }}
          onClick={() => {
            navigate("/installers");
          }}
        >
          <div className="left-img">
            <img
              src={`${image}/Assets/LeftPanel/teams.svg`}
              alt=""
              className={`default-image ${isInstallersActive ? "hide" : ""}`}
            />
            <img
              src={`${image}/Assets/LeftPanel/teams-white.svg`}
              alt=""
              className={`selected-image ${isInstallersActive ? "" : "hide"}`}
            />
          </div>
          <div className="left-text">Installers</div>
        </button>
        <button
          className={`left-part ${isConfigurationActive ? "active" : ""}`}
          style={{ border: "none", display: "none" }}
          onClick={() => {
            navigate("/config");
          }}
          disabled
        >
          <div className="left-img">
            <img
              src={`${image}/Assets/LeftPanel/configuration.svg`}
              alt=""
              className={`default-image ${isConfigurationActive ? "hide" : ""}`}
            />
            <img
              src={`${image}/Assets/LeftPanel/configuration-white.svg`}
              alt=""
              className={`selected-image ${
                isConfigurationActive ? "" : "hide"
              }`}
            />
          </div>
          <div className="left-text">Configuration</div>
        </button>
        <button
          className={`left-part`}
          style={{ border: "none" }}
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
        >
          <div className="left-img">
            <img
              src={`${image}/Assets/LeftPanel/logout.svg`}
              alt=""
              className={`default-image`}
            />
            <img
              src={`${image}/Assets/LeftPanel/logout-white.svg`}
              alt=""
              className={`selected-image`}
            />
          </div>
          <div className="left-text">Log Out</div>
        </button>
      </div>
    </div>
  );
};

export default Aside;
