import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";

const Map = () => {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: -25.274398,
    lng: 133.775136,
  });

  const uveaaLocation = {
    lat: -27.4726827,
    lng: 153.029611,
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "420px",
        marginLeft: "60px",
      }}
      center={selectedLocation}
      zoom={3.7}
      mapTypeId="hybrid"
      options={{
        disableDefaultUI: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      <MarkerF position={uveaaLocation} />
    </GoogleMap>
  );
};

export default Map;
