import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Aside from "./FixedBar/Aside";
import BarGraph from "./Components/Bargraph";
import Map from "./Components/Map";
// import HeaderComponent from "./HeaderComponent";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Dashboard = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");
  const [count, setCount] = useState();

  const boxStyle = {
    width: "350px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    borderRadius: "10px",
    margin: "10px",
    position: "relative",
    boxShadow: "5px 10px 10px rgba(0, 10, 120, 0.5)",
    gap: "30px",
    backgroundColor: "#e1eaff",
    cursor:"pointer"
  };

  const numberStyle = {
    fontSize: "30px",
    fontWeight: "bold",
  };

  const textStyle = {
    fontSize: "22px",
    textAlign: "center",
  };

  //API Call to get Count Starts
  const getCount = async () => {
    try {
      const resp = await axios.get(`${baseURL}/admin/counts`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = resp.data;
      setCount(data);
    } catch (err) {
      console.log("Error:", err);
    }
  };
  useEffect(() => {
    getCount();
  }, []);
  //API Call to get Count Endss

  // Static data for Graph
  const data = [
    {
      year: 2024,
      Retailers: count?.retailerCount,
      Traders: count?.traderCount,
      Installers: count?.installerCount,
    },
    { year: 2025, Retailers: 0, Traders: 0, Installers: 0 },
    { year: 2026, Retailers: 0, Traders: 0, Installers: 0 },
    { year: 2027, Retailers: 0, Traders: 0, Installers: 0 },
    { year: 2028, Retailers: 0, Traders: 0, Installers: 0 },
  ];
  return (
    <div className="MainOuterContainer">
      <Aside />
      <div className=" main-part-ratailer">
        <div className="row">
          {/* <HeaderComponent
            PageTitle={"Admin"}
            location="Dashboard"
            JobType="Solar PV"
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "30px",
          }}
        >
          <div style={boxStyle} onClick={() => navigate("/traders")}>
            <div style={textStyle}>No. of Traders</div>
            <div style={numberStyle}>{count?.traderCount}</div>
          </div>
          <div style={boxStyle} onClick={() => navigate("/retailers")}>
            <div style={textStyle}>No. of Retailers</div>
            <div style={numberStyle}>{count?.retailerCount}</div>
          </div>
          <div style={boxStyle} onClick={() => navigate("/installers")}>
            <div style={textStyle}>No. of Installers</div>
            <div style={numberStyle}>{count?.installerCount}</div>
          </div>
        </div>
        <div style={{ height: "80vh", paddingRight: "70px" }}>
          <div style={{ display: "flex", marginTop: "100px", height: "100%" }}>
            <BarGraph
              data={data}
              width={200}
              height={800}
              dataKeyX="year"
              dataKeyY="persons"
              barColor="#8884d8"
            />
            <div style={{ width: "42%", height: "90px" }}>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
