import React, { useEffect, useState } from "react";
import Aside from "./FixedBar/Aside";
import axios from "axios";
import moment from "moment";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const Jobs = () => {
  const token = localStorage.getItem("userToken");
  const [jobs, setJobs] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1");

  const getJobs = async () => {
    try {
      const resp = await axios.get(`${baseURL}/admin/allJob`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp?.data;
      setJobs(data.reverse());
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filteredJobs = jobs.filter((job) => {
    const searchTerm = searchQuery;
    const statusMatches = selectedStatus !== "1"
      ? job.statusCode.toString() === selectedStatus
      : true;
    return (
      statusMatches &&
      (
        job.jobIdentifyId.toLowerCase().includes(searchTerm) ||
        `${job.trader?.basicInformation?.firstName} ${job.trader?.basicInformation?.lastName}`
          .toLowerCase()
          .includes(searchTerm) ||
        `${job.user?.basicInformation?.firstName} ${job.user?.basicInformation?.lastName}`
          .toLowerCase()
          .includes(searchTerm) ||
        (job.installingFor === "Individual"
          ? `${job.ownerDetails?.firstName} ${job.ownerDetails?.lastName}`
              .toLowerCase()
              .includes(searchTerm)
          : job.ownerDetails?.companyName.toLowerCase().includes(searchTerm))
      )
    );
  });

  const handleButtonClick = async (status, id) => {
    try {
      const requestData = {
        newStatus: status,
      };
      const response = await axios.patch(
        `${baseURL}/users/jobschangeStatus/${id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Job Status Updated Successfully");
        window.location.reload();
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  const handleSatusChange=(e)=>{
    if(e.target.value =="1"){
      setSelectedStatus("Status")
    }
  }
  return (
    <div className="MainOuterContainer">
      <Aside />
      <div className="main-part-retailer">
        <Box sx={{ width: "86vw" }}>
          <div className="search-filter-section" style={{display:"flex",gap:'80px',margin:"20px",width:"50%"}}>
            <TextField
              type="text"
              placeholder="Search by Owner Name, Retailer Name, Trader Name, Status or PV Job Number"
              name="search"
              className="input-search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
            />
            
          </div>

          <TableContainer component={Paper} style={{margin:'30px 10px'}} >
            <Table aria-label="collapsible table">
              <TableHead style={{background:"rgb(239, 244, 250)",borderRadius:"15px"}}>
                <TableRow>
                  <TableCell />
                  <TableCell>Job Id</TableCell>
                  <TableCell>Trader Name</TableCell>
                  <TableCell>Retailer Name</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>
           
            <Select
              id="statusFilter"
              value={selectedStatus ||"Status"}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="status-dropdown"
              fullWidth
              defaultValue="Status"
            >
              <MenuItem value="1">Status</MenuItem>
              <MenuItem value="3">In-Progress</MenuItem>
              <MenuItem value="6">Job-Finished</MenuItem>
              <MenuItem value="10">Submitted</MenuItem>
              <MenuItem value="11">Waiting For Approval</MenuItem>
              <MenuItem value="14">Approved</MenuItem>
              <MenuItem value="13">Failed</MenuItem>
              <MenuItem value="9">Cancelled</MenuItem>
            </Select></TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Installation Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredJobs.map((row, index) => (
                  <Row key={row._id} row={row} handleButtonClick={handleButtonClick} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
};

const Row = ({ row, handleButtonClick }) => {
  const [open, setOpen] = useState(false);

const statusColor =
    row?.statusCode === 9 || row?.statusCode === 13
      ? "red"
      : row?.statusCode === 6 || row?.statusCode === 11
      ? "orange"
      : row?.statusCode === 10 || row?.statusCode === 14
      ? "green"
      : "blue";

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {/* <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton> */}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.jobIdentifyId}
        </TableCell>
        <TableCell>
          {row?.trader?.basicInformation?.firstName} {row?.trader?.basicInformation?.lastName}
        </TableCell>
        <TableCell>
          {row?.user?.basicInformation?.firstName} {row?.user?.basicInformation?.lastName}
        </TableCell>
        <TableCell>
          {row?.installingFor === "Individual"
            ? `${row?.ownerDetails?.firstName} ${row?.ownerDetails?.lastName}`
            : row?.ownerDetails?.companyName}
        </TableCell>
        <TableCell style={{ color: statusColor, fontWeight: "600" }}>
          {row?.statusDescription.toUpperCase()}
        </TableCell>
        <TableCell>
          {row?.statusCode === 6 ? (
            <button
              className="ReAssignButtons"
              style={{ width: "auto", border: "none" }}
              onClick={() => {
                handleButtonClick(3, row?._id);
              }}
            >
              Re-Assign to Installer
            </button>
          ) : (
            null
          )}

          {row?.statusCode === 10 || row?.statusCode === 13 ? (
            <>
              <button
                className="ReAssignButtons"
                onClick={() => {
                  handleButtonClick(3, row?._id);
                }}
              >
                Re-Assign to Installer
              </button>
              <button
                className="ReAssignButtons"
                onClick={() => {
                  handleButtonClick(6, row?._id);
                }}
              >
                Re-Assign to Retailer
              </button>
            </>
          ) : row?.statusCode === 11 ? (
            <>
              <button
                className="ReAssignButtons"
                onClick={() => {
                  handleButtonClick(14, row?._id);
                }}
              >
                Approve Job
              </button>
              <button
                className="ReAssignButtons"
                onClick={() => {
                  handleButtonClick(13, row?._id);
                }}
              >
                Fail Job
              </button>
            </>
          ) : null}
        </TableCell>
        <TableCell>
          {row?.installationDate?.installingDate
            ? moment(row?.installationDate?.installingDate).format("DD-MM-YYYY")
            : "-"}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Job Details
              </Typography>
                {row?.jobType=="PV"?(
              <Typography>
                  Installer : {row?.installerName || 'not selected'}
              </Typography>
                ):
                  (
                    <Typography>
                    Plumber : {row?.plumberName || 'not selected'}
                    </Typography>
                  )
                }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
};

export default Jobs;