import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Aside from "../FixedBar/Aside";
import { useLocation } from "react-router-dom";
import AddTraderAndRetailer from "./TradersAndRetailers/AddTraderAndRetailer";
import ProfileTraderAndRetailer from "./TradersAndRetailers/ProfileTraderAndRetailer";
import EditTraderAndRetailer from "./TradersAndRetailers/EditTraderAndRetailer";
import EditInstallationTeam from "./Installers/EditInstallationTeam";
import ProfileInstallationTeam from "./Installers/ProfileInstallationTeam";
import { Tooltip } from "@mui/material";
// import AddModel from "./Trader/Teamdashboard/AddModel";
// import HeaderComponent from "./Dashboard/CommonComponents/HeaderComponent";
// import { Send } from "@mui/icons-material";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const RetailersList = () => {
  const token = localStorage.getItem("userToken");
  const location = useLocation();

  const userType =
    location.pathname === "/traders"
      ? "Trader"
      : location.pathname === "/retailers"
      ? "Retailer"
      : "Installer";

  // Team Data Mapping Starts
  const [dataShow, setDataShow] = useState([]);
  const getData = async () => {
    let apiUrl = "";
    if (location.pathname === "/traders") {
      apiUrl = `${baseURL}/admin/tradersList`;
    } else if (location.pathname === "/retailers") {
      apiUrl = `${baseURL}/admin/retailersList`;
    } else {
      apiUrl = `${baseURL}/admin/installersList`;
    }
    try {
      const resp = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = resp.data;
      setDataShow(data);
    } catch (err) {
      console.log("Error:", err);
    }
  };
  useEffect(() => {
    setDataShow([]);
    getData();
  }, [location.pathname]);

  const reverseData = [...dataShow].reverse();
  // Team Data Mapping Ends

  //Add Trader Model Starts
  const [isAddModelOpen, setAddModelOpen] = useState(false);
  const [isViewTeamOpen, setViewTeamOpen] = useState(false);
  const [isEditTeamOpen, setEditTeamOpen] = useState(false);
  const [showFreezeConfirmation, setShowFreezeConfirmation] = useState(false);
  const [status, setStatus] = useState(false);

  //Add Trader Model Ends

  //View Member Model Starts
  const viewTeam = (id) => {
    userType === "Trader"
      ? localStorage.setItem("traderId", id)
      : userType === "Retailer"
      ? localStorage.setItem("retailerId", id)
      : localStorage.setItem("installerId", id);

    setViewTeamOpen(true);
    document.body.classList.add("page-modal-open");
  };
  //View Member Model Ends

  //Edit Member Model Starts
  const editTeam = (id) => {
    userType === "Trader"
      ? localStorage.setItem("traderId", id)
      : userType === "Retailer"
      ? localStorage.setItem("retailerId", id)
      : localStorage.setItem("installerId", id);

    setEditTeamOpen(true);
  };
  //Edit Member Model Ends

  //Freeze Unfreeze Starts
  const getIdToFreeze = (id, status) => {
    userType === "Trader"
      ? localStorage.setItem("traderId", id)
      : userType === "Retailer"
      ? localStorage.setItem("retailerId", id)
      : localStorage.setItem("installerId", id);
    setStatus(status);
    setShowFreezeConfirmation(true);
  };

  const handleFreeze = async (e) => {
    e.preventDefault();
    const id =
      userType === "Trader"
        ? localStorage.getItem("traderId")
        : localStorage.getItem("retailerId");
    const data = {
      isFreezed: !status,
      userType: userType,
    };
    try {
      const response = await axios.patch(`${baseURL}/freeze/user/${id}`, data, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        alert(`This account has been ${status ? "Unfrozen" : "Frozen"}.`);
        setStatus(false);
        window.location.reload();
      } else if (response.status === 400) {
        alert("User Already Exists: Email or username is already taken");
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
    }
    setShowFreezeConfirmation(false);
  };
  //Freeze Unfreeze Ends
  return (
    <div id="myModel" className="modal">
      <div style={{ display: "flex" }}>
        <div>
          <Aside />
        </div>
        <div className="main-part-ratailer">
          {/* <HeaderComponent
            PageTitle={userType == "Trader" ? "Retailer" : "Teams"}
            subTitle={`Dashboard / ${
              userType == "Trader" ? "Retailer" : "Teams"
            }`}
          /> */}
          <div className="row">
            <div className="TemsTableHeadingContainer">
              <div style={{ padding: "10px" }}>
                <b>{userType}s</b>
              </div>

              <div className="team-btn">
                <button
                  className="btn-add"
                  style={{ display: userType !== "Trader" ? "none" : "" }}
                  onClick={() => {
                    setAddModelOpen(true);
                  }}
                >
                  Add
                </button>
              </div>
            </div>
            <div>
              <table
                style={{
                  flexDirection: "column",
                  gap: "10px",
                  textAlign: "center",
                  overflowY: "scroll",
                }}
                className="team-details"
              >
                <th
                  className="team-main-bg TeamsTableHeading"
                  style={{ width: "100%" }}
                >
                  {/* <div className=""> */}
                  <td className="team-data-main">Name</td>
                  <td className="team-data-role">Role</td>
                  <td className="team-data-email" style={{ width: "24%" }}>
                    Email ID
                  </td>
                  <td className="team-data-mobile">
                    {userType === "Trader"
                      ? "Mobile"
                      : userType === "Retailer"
                      ? "Trader"
                      : userType === "Installer"
                      ? "Retailers"
                      : ""}
                  </td>
                  {userType === "Installer" ? (
                    <td className="team-data-mobile">Status</td>
                  ) : null}

                  <td className="team-data-actions">Actions</td>
                  {/* </div> */}
                </th>
                {reverseData.length > 0 ? (
                  reverseData.map((data, groupIndex) => {
                    return (
                      // <div className="team-details">
                      <tr className="TeamDetailsRowData" key={groupIndex}>
                        <td
                          className="team-data-main"
                          style={{
                            overflowWrap: "break-word",
                          }}
                        >
                          {userType !== "Installer" ? (
                            <>
                              {data?.basicInformation?.firstName}{" "}
                              {data?.basicInformation?.lastName}
                            </>
                          ) : (
                            <>{data?.installer?.name}</>
                          )}
                        </td>
                        <td className="team-data-role">
                          {userType !== "Installer" ? (
                            <>{data?.basicInformation?.role}</>
                          ) : (
                            <>
                              {data?.installer?.selectYourRole[0]}{" "}
                              {data?.installer?.selectYourRole[1]}{" "}
                              {data?.installer?.selectYourRole[2]}{" "}
                              {data?.installer?.selectYourRole[3]}{" "}
                              {data?.installer?.selectYourRole[4]}
                            </>
                          )}
                        </td>
                        <td className="team-data-email">
                          {userType !== "Installer" ? (
                            <>{data?.basicInformation?.emailId}</>
                          ) : (
                            <>{data?.installer?.emailId}</>
                          )}
                        </td>
                        <td className="team-data-mobile">
                          {userType === "Trader" ? (
                            <>{data?.companyInformation?.mobile}</>
                          ) : userType === "Retailer" ? (
                            <>
                              {data?.traderData?.basicInformation?.firstName}{" "}
                              {data?.traderData?.basicInformation?.lastName}
                            </>
                          ) : userType === "Installer" ? (
                            <>
                              {data?.retailerData
                                ?.map(
                                  (retailer) =>
                                    `${retailer?.basicInformation?.firstName} ${retailer?.basicInformation?.lastName}`
                                )
                                .join(", ")}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                        {userType === "Installer" ? (
                          <td
                            className="team-data-mobile"
                            style={{
                              color:
                                data?.isDeleted === true
                                  ? "red"
                                  : data?.isActive === true
                                  ? "green"
                                  : "blue",
                            }}
                          >
                            {data?.isDeleted === true
                              ? "Deleted"
                              : data?.isActive === true
                              ? "Active"
                              : "Paused"}
                          </td>
                        ) : null}

                        <td className="team-data-actions">
                          <Tooltip title="Edit">
                            <button
                              className="add-action"
                              onClick={() => editTeam(data._id)}
                            >
                              <img
                                src={`${image}/Assets/Teams/add-btn.svg`}
                                alt=""
                              />
                            </button>
                          </Tooltip>
                          <Tooltip title="View">
                            <button
                              className="view-action"
                              onClick={() => viewTeam(data._id)}
                            >
                              <img
                                src={`${image}/Assets/Teams/view.svg`}
                                alt=""
                              />
                            </button>
                          </Tooltip>
                          {userType !== "Installer" ? (
                            <Tooltip
                              title={data.isFreezed ? "Unfreeze" : "Freeze"}
                            >
                              <button
                                className="notview-action"
                                onClick={() =>
                                  getIdToFreeze(data._id, data.isFreezed)
                                }
                              >
                                <img
                                  src={`${image}/Assets/Teams/not-view.svg`}
                                  alt=""
                                />
                              </button>
                            </Tooltip>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 100px 0px 20px",
                    }}
                  >
                    <p>No {userType} Found</p>
                  </div>
                )}
              </table>
            </div>
          </div>

          <div className="row">
            {isAddModelOpen && (
              <AddTraderAndRetailer
                onClose={() => {
                  setAddModelOpen(false);
                  localStorage.removeItem("traderId");
                  localStorage.removeItem("retailerId");
                  localStorage.removeItem("installerId");
                }}
              />
            )}
            {isEditTeamOpen && (
              <>
                {userType === "Trader" || userType === "Retailer" ? (
                  <EditTraderAndRetailer
                    onClose={() => {
                      setEditTeamOpen(false);
                      localStorage.removeItem("traderId");
                      localStorage.removeItem("retailerId");
                    }}
                  />
                ) : (
                  <EditInstallationTeam
                    onClose={() => {
                      setEditTeamOpen(false);
                      localStorage.removeItem("installerId");
                    }}
                  />
                )}
              </>
            )}
            {isViewTeamOpen && (
              <>
                {userType === "Trader" || userType === "Retailer" ? (
                  <ProfileTraderAndRetailer
                    onClose={() => {
                      setViewTeamOpen(false);
                      localStorage.removeItem("traderId");
                      localStorage.removeItem("retailerId");
                    }}
                  />
                ) : (
                  <ProfileInstallationTeam
                    onClose={() => {
                      setViewTeamOpen(false);
                      localStorage.removeItem("installerId");
                    }}
                  />
                )}
              </>
            )}

            {showFreezeConfirmation && (
              <div className="invite-model-backdrop">
                <div
                  className="delete-model-content"
                  style={{ width: "502px" }}
                >
                  <h2>
                    Are you sure you want to {status ? "Unfreeze" : "Freeze"}?
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button className="btn-cancel" onClick={handleFreeze}>
                      Yes
                    </button>
                    <button
                      className="button-invite"
                      onClick={() => {
                        localStorage.removeItem("retailerId");
                        localStorage.removeItem("installerId");
                        setShowFreezeConfirmation(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailersList;
