import React, { useState } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const DeleteInstaller = () => {
  const [eye, setEye] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");

  const eyeChange = () => {
    setEye(!eye);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const requestData = {
      emailId: emailId,
      password: password,
    };

    let apiUrl = `${baseURL}/admin/deleteInstaller`;
    try {
      const response = await axios.delete(apiUrl, {
        data: requestData,
      });
      if (response.status === 200 || response.status === 201) {
        alert("Installer Deleted Successfully");
        setPassword("");
        setEmailId("");
      }
    } catch (error) {
      alert(error?.response?.data?.message);
      setPassword("");
      setEmailId("");
    }
  };

  return (
    <div className="login-container">
      <div className="bg-overlay"></div>
      <div style={{ height: "72%" }}>
        <div className="logo">
          <img
            src="/Assets/Login/uveaa-logo.svg"
            alt="Logo Here"
            width={"200px"}
          />
        </div>
        <section
          className="login-box main-box"
          style={{ height: "360px", marginTop: "25px" }}
        >
          <div className="retailer-logo">
            <p>Delete Installer</p>
          </div>
          <div className="row">
            <form className="form-container">
              <label htmlFor="username" className="Label-text-Login">
                Email ID
              </label>
              <div className="user-form">
                <input
                  id="username"
                  type="text"
                  value={emailId || ""}
                  onChange={(e) => setEmailId(e.target.value)}
                  placeholder="Email"
                />
                <div className="login-form-imag"></div>
              </div>
              <div className="pas-form">
                <div className="text-password">
                  <label htmlFor="password" className="label-password">
                    Password
                  </label>
                </div>
                <input
                  id="password"
                  className="psd-toggle"
                  type={eye ? "text" : "password"}
                  value={password || ""}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="login-form-imag lock-password"></div>
                <button onClick={eyeChange} type="button" className="eye-btn">
                  <img
                    className="eye-login"
                    src={
                      !eye
                        ? `${image}/Assets/Login/openview-eye.svg`
                        : `${image}/Assets/Login/view-eye.svg`
                    }
                    alt=""
                  />
                </button>
              </div>

              <button
                loging="true"
                className="login-btn"
                style={{ marginTop: "10px" }}
                onClick={handleDelete}
              >
                DELETE
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DeleteInstaller;
