import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const ResetPswd = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    adminId: _id,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [eye, setEye] = useState(false);
  const [eye1, setEye1] = useState(false);

  const eyeChange = () => setEye(!eye);
  const eye1Change = () => setEye1(!eye1);

  const formSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/admin/resetPassword`,
        formData
      );
      if (response.status === 200 || response.status === 201) {
        alert("Password Updated successfully");
        navigate("/");
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <form
          onSubmit={formSubmit}
          className="ForgetPassword-systemDetails-container"
        >
          <div className="bg-overlay"></div>
          <Grid container xs={12} className="resetPasswordPage">
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <img
                src="/Assets/Login/uveaa-logo.svg"
                alt="Logo Here"
                width={"200px"}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={4}
              className="resetpassword-container"
            >
              <Grid item xs={12}>
                <p className="reset-text">Reset Password</p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Box className="pas-form-reset" style={{ width: "100%" }}>
                  {eye ? (
                    <input
                      className="psd-toggle-reset"
                      type="text"
                      name="password"
                      placeholder="New Password"
                      value={formData.password || ""}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <input
                      className="psd-toggle-reset"
                      type="password"
                      name="password"
                      placeholder="New Password"
                      value={formData.password || ""}
                      onChange={handleInputChange}
                    />
                  )}
                  <div className="login-form-imag lock-password">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="/Assets/Login/lock-main.svg"
                      alt=""
                    />
                  </div>
                  <button onClick={eyeChange} type="button" className="eye-btn">
                    {eye ? (
                      <img
                        className="eye-login"
                        src={`${image}/Assets/Login/openview-eye.svg`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="eye-login"
                        src={`${image}/Assets/Login/view-eye.svg`}
                        alt=""
                      />
                    )}
                  </button>
                </Box>
                <Box className="pas-form-reset" style={{ width: "100%" }}>
                  {eye1 ? (
                    <input
                      className="psd-toggle-reset"
                      type="text"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={formData.confirmPassword || ""}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <input
                      className="psd-toggle-reset"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={formData.confirmPassword || ""}
                      onChange={handleInputChange}
                    />
                  )}
                  <button
                    onClick={eye1Change}
                    type="button"
                    className="eye-btn"
                  >
                    {eye1 ? (
                      <img
                        className="eye-login"
                        src={`${image}/Assets/Login/openview-eye.svg`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="eye-login"
                        src={`${image}/Assets/Login/view-eye.svg`}
                        alt=""
                      />
                    )}
                  </button>

                  <div className="login-form-imag lock-password">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      alt=""
                      src="/Assets/Login/lock-main.svg"
                    />
                  </div>
                  <button
                    onClick={eye1Change}
                    type="button"
                    className="eye-btn"
                  >
                    {eye1 ? (
                      <img
                        className="eye-login"
                        src={`${image}/Assets/LogIn/openview-eye.svg`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="eye-login"
                        src={`${image}/Assets/LogIn/view-eye.svg`}
                        alt=""
                      />
                    )}
                  </button>
                </Box>
                <div className="reset-password-text">
                  <p>Please reset your password for your account.</p>
                </div>
                <div className="ForgetPassword-button-container">
                  <div className="submitbutton-box">
                    <button type="submit" className="next action-button">
                      Submit
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default ResetPswd;
