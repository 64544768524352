import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Aside from "./FixedBar/Aside";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const MobileVersion = () => {
  const token = localStorage.getItem("userToken");

  //Add Mobile Version POPUP Starts
  const [addMobileVersionOpen, setAddMobileVersionOpen] = useState(false);
  //Add Mobile Version POPUP Ends

  //Add formData Starts
  const [addFormData, setAddFormData] = useState({
    type: "",
    version: "",
    forceUpdate: 0,
  });
  //Add formData Ends

  //Function to Add Mobile Version starts
  const handleAddMobileVersionInput = (e) => {
    const { name, value } = e.target;
    setAddFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  //Function to Add Mobile Version Ends

  //API Call to submit FormData Starts
  const handlePostSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/mobileVersion/add`,
        addFormData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Mobile App Version Added Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        alert("Error Adding Mobile App Version");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends
  //Add Mobile Version POPUP Ends

  //Edit formData Starts
  const [editFormData, setEditFormData] = useState({
    type: "",
    version: "",
    forceUpdate: 0,
  });

  //Edit formData Ends
  //Edit Mobile Version POPUP Starts
  const [editMobileVersionOpen, setEditMobileVersionOpen] = useState(false);

  // Edit Approved Mobile Version Start from here
  const editMobileVersion = (mobileId) => {
    localStorage.setItem("mobileId", mobileId);
    const result = reverseMobileVersionShow.filter(
      (item) => item._id === mobileId
    );
    // setEditFormData(result[0]);
    setEditFormData((prev) => ({
      ...prev,
      type: result[0].type,
      version: result[0].version,
      forceUpdate: result[0].forceUpdate,
    }));
    setEditMobileVersionOpen(true);
  };

  // Edit Approved Mobile Version End here

  //Function to Edit Mobile Version starts
  const handleEditMobileVersionInput = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  //Function to Edit Mobile Version Ends

  //API Call to submit FormData Starts
  const handlePatchSubmit = async (e) => {
    e.preventDefault();
    try {
      const mobileVersion = localStorage.getItem("mobileId");
      const response = await axios.patch(
        `${baseURL}/mobileVersion/edit/${mobileVersion}`,
        editFormData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Mobile App Version Updated Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
        localStorage.removeItem("mobileId");
      } else {
        alert("Error Updating Mobile App Version");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends
  //Edit Mobile Version POPUP Ends

  //View Mobile Version POPUP Starts
  //formData Starts
  const [mobileVersionShow, setMobileVersionShow] = useState([]);

  //Mobile Version data mapping starts
  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/mobileVersion/details`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data;
        setMobileVersionShow(data);
      } catch (err) {
        console.log("Error:", err);
      }
    };
    getData();
  }, []);

  const reverseMobileVersionShow = [...mobileVersionShow].reverse();

  //Mobile Version Data Mapping Ends
  //View Mobile Version POPUP Ends

  return (
    <>
      {addMobileVersionOpen && (
        <div
          className="invite-model-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <div className="invite-model-content" style={{ height: "355px" }}>
            <div className="invite-model-header">
              <h3>Add Mobile App Version</h3>
              <button
                className="invite-model-close-btn"
                onClick={() => {
                  setAddMobileVersionOpen(false);
                }}
              >
                ✕
              </button>
            </div>
            <div className="row">
              <span className="popupbottom-border"></span>
            </div>
            <form
              className="invite-model-form"
              style={{ gap: "0px" }}
              onSubmit={handlePostSubmit}
            >
              <input
                type="text"
                className="input-field popup-email"
                style={{ width: "434px" }}
                value={addFormData.type || ""}
                onChange={handleAddMobileVersionInput}
                placeholder="Mobile Type"
                name="type"
              />
              <input
                type="text"
                className="input-field popup-email"
                style={{ width: "434px" }}
                value={addFormData.version || ""}
                onChange={handleAddMobileVersionInput}
                placeholder="App Version"
                name="version"
              />
              <input
                type="number"
                className="input-field popup-email"
                style={{ width: "434px" }}
                value={addFormData.forceUpdate || ""}
                onChange={handleAddMobileVersionInput}
                placeholder="Force Update"
                name="forceUpdate"
              />

              <div className="row">
                <span className="popupbottom-border"></span>
              </div>
              <div className="row">
                <div style={{ display: "flex" }}>
                  <div className="footer-popupbtn">
                    <button
                      type="button"
                      onClick={() => {
                        setAddMobileVersionOpen(false);
                      }}
                      className="btn-cancel"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="button-invite">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {editMobileVersionOpen && (
        <div
          className="invite-model-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <div className="invite-model-content" style={{ height: "auto" }}>
            <div className="invite-model-header">
              <h3>Edit Mobile App Verison </h3>
              <button
                className="invite-model-close-btn"
                onClick={() => {
                  setEditMobileVersionOpen(false);
                  localStorage.removeItem("mobileId");
                }}
              >
                ✕
              </button>
            </div>
            <div className="row">
              <span className="popupbottom-border"></span>
            </div>
            <form className="invite-model-form" onSubmit={handlePatchSubmit}>
              <input
                type="text"
                className="input-field popup-email"
                value={editFormData?.type || ""}
                onChange={handleEditMobileVersionInput}
                placeholder="Mobile Type"
                name="type"
                readOnly
              />
              <input
                type="text"
                className="input-field popup-email"
                value={editFormData?.version || ""}
                onChange={handleEditMobileVersionInput}
                placeholder="App Version"
                name="version"
              />
              <input
                type="number"
                className="input-field popup-email"
                value={editFormData?.forceUpdate}
                onChange={handleEditMobileVersionInput}
                placeholder="Force Update"
                name="forceUpdate"
              />

              <div className="row">
                <span className="popupbottom-border"></span>
              </div>
              <div className="row">
                <div style={{ display: "flex" }}>
                  <div className="footer-popupbtn">
                    <button
                      type="button"
                      onClick={() => {
                        setEditMobileVersionOpen(false);
                        localStorage.removeItem("mobileId");
                      }}
                      className="btn-cancel"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="button-invite">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <div id="myModel" className="modal">
        <div style={{ display: "flex" }}>
          <div>
            <Aside />
          </div>
          <div className="live-Price-main-section">
            <div className="row">
              <div style={{ display: "flex" }}>
                <div className="team-bg">
                  <b>Mobile Version</b>
                </div>
              </div>
              <div>
                <table
                  style={{
                    flexDirection: "column",
                    gap: "10px",
                    textAlign: "center",
                  }}
                  className="team-details"
                >
                  <th className="CERPage-main-bg TeamsTableHeading">
                    <td className="team-data-main" style={{ width: "100%" }}>
                      <b>Mobile OS</b>
                    </td>
                    <td className="team-data-main" style={{ width: "100%" }}>
                      <b>Version</b>
                    </td>
                    <td className="team-data-main" style={{ width: "100%" }}>
                      <b>Force Update</b>
                    </td>
                    <td className="team-data-main" style={{ width: "100%" }}>
                      <b>Actions</b>
                    </td>
                  </th>

                  {reverseMobileVersionShow ? (
                    reverseMobileVersionShow.map((item, index) => (
                      <tr className="TeamDetailsRowData" key={index}>
                        <td
                          className="team-data-main"
                          style={{ width: "100%" }}
                        >
                          {item?.type}
                        </td>
                        <td
                          className="team-data-main"
                          style={{ width: "100%" }}
                        >
                          {item?.version}
                        </td>
                        <td
                          className="team-data-main"
                          style={{ width: "100%" }}
                        >
                          {item?.forceUpdate === 1 ? "Yes" : "No"}
                        </td>
                        <td
                          className="team-data-main"
                          style={{ width: "100%" }}
                        >
                          <button
                            className="add-action"
                            onClick={() => editMobileVersion(item?._id)}
                          >
                            <img
                              src={`${image}/Assets/Teamdashboard/add-btn.svg`}
                              alt=""
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px 100px 0px 20px",
                      }}
                    >
                      <p>No Version Found</p>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileVersion;
